import React from 'react';
import { BaseLayout } from 'components';
import { Link } from 'react-router-dom';
import { ShortBackground } from 'images';
import { Container, H1, SvgWrapper, isMobile, device } from 'utils';
import styled from '@emotion/styled';
import { SecurityIcon, SecurityDateIcon, ConnectedIcon } from 'images';
import { Helmet } from 'react-helmet';
const FeesPageWrapper = styled.div`
  .intro {
    text-align: center;
    margin-bottom: 150px;
    margin-top: 0;
    @media ${device.allMobile} {
      padding: 0 15px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .icon {
      position: relative;
      left: -55px;
      margin-bottom: 50px;
    }
    h1 {
      @media ${device.allMobile} {
        font-size: 24px;
      }
    }
    p {
      font-size: 22px;
      margin: auto;
      @media ${device.allMobile} {
        font-size: 16px;
      }
      span {
        font-weight: bold;
      }
    }
    .margin-less {
      @media ${device.allMobile} {
        margin-top: 0;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      margin: 50px auto;
      margin-bottom: 100px;
      margin-top: 150px;
      @media ${device.allMobile} {
        display: flex;
        margin-bottom: 50px;
        margin-top: 50px;
        flex-wrap: wrap;
      }

      &--icon {
        width: 40%;

        @media ${device.allMobile} {
          width: 100%;
          order: 1;
        }
      }
      &--content {
        width: 60%;
        text-align: left;
        @media ${device.allMobile} {
          width: 100%;
          text-align: center;
          order: 2;
        }
        &.payment {
          h2 {
            font-size: 22px;
          }
          p {
            text-align: left;
          }
          ul {
            padding: 0;
            list-style: none;
            li {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
`;
export default function TransparencyAndTrustPage() {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Vertrauen und Sicherheit bei commonsplace. Maßnahmen und Best Practices zum Schutz der Daten und zur Gewährleistung sicherer Transaktionen."
        />
      </Helmet>
      <BaseLayout
        bannerComponent={
          <div style={{ textAlign: 'center' }}>
            <H1>Vertrauen und Sicherheit</H1>
          </div>
        }
        container={false}
        maxHeightProps="500px"
        maxHeightMobileProps="250px"
        img={ShortBackground}
      >
        <Container>
          <FeesPageWrapper>
            <div className="intro">
              <h2>Das Vertrauen der Community</h2>
              <p>
                commonsplace ist eine Community-Plattform und ein Ort für
                vielfältige und kreative Ideen, engagierte Projektersteller und
                großzügige Unterstützer. Transparenz, Sicherheit und Vertrauen
                machen das Miteinander und die Harmonie auf commonsplace aus.
                Wir setzen alles darauf, euch eine sichere und vertrauensvolle
                Plattform zu bieten!
              </p>
              <div className="intro__content">
                <div className="intro__content--content">
                  <p>
                    Unsere Plattform nutzt beste Technologien, um jede Zahlung
                    verschlüsselt und sicher zu gewährleisten. Die Identität
                    eines jeden Projektinitiators wird von unserem
                    Zahlungsdienstpartner Stripe verifiziert. Außerdem weist
                    Stripe jedem Projektinitiator ein eigenes Client Money-Konto
                    zu. Auf dem Money-Konto wird das gesammelte Geld bis zur
                    Ausschüttung sicher, treuhänderisch und vertraglich
                    verwaltet. commonsplace verfügt zu keiner Zeit über das
                    Geld. Sollte eine Insolvenz seitens commonsplace auftreten,
                    überweist Stripe das Geld an die Unterstützer bzw. an den
                    Projektinitiator zurück. Projektinitiator, bei denen
                    Anzeichen von Missbrauch erkannt werden, werden aus dem
                    System unmittelbar entfernt. Wir behalten uns das Recht vor,
                    Projekte abzulehnen, die gegen
                    <Link to="/guidelines"> unsere Richtlinien</Link> verstoßen.
                  </p>
                </div>
                <div className="intro__content--icon">
                  <SvgWrapper width="90%" height="auto">
                    <SecurityIcon />
                  </SvgWrapper>
                </div>
              </div>
              <h2>Transparenz und Vertrauen beim Projektinitiator</h2>
              <div className="intro__content margin-less">
                <div className="intro__content--icon">
                  <SvgWrapper width="90%" height="auto">
                    <SecurityDateIcon />
                  </SvgWrapper>
                </div>
                <div className="intro__content--content">
                  <p>
                    Der Projektinitiator gibt bei der Erstellung seines Projekts
                    eine zeitliche Abschätzung an, wann das Projekt realisiert
                    wird. Der Unterstützer wird durch regelmäßige Updates des
                    Projektinitiatoren nachverfolgen können, wie sich das
                    Projekt entwickelt. Verzögerungen und unerwartete Szenarien
                    sind beim Projektmanagement ganz normal. Wir vertrauen
                    darauf, dass der Projektersteller eigenständig, ehrlich und
                    transparent die Entwicklungen seines Projekts an seine
                    Unterstützer kommuniziert. Die Verantwortung die
                    Unterstützer mit Hilfe von Updates auf der Projektseite über
                    die Neuigkeiten und Updates zu informieren, liegt beim
                    Projektersteller. Sollte das Fundingziel nicht erreicht
                    werden, erhalten Projektinitiatoren von sozialen Initiativen
                    die Unterstützungen trotzdem und müssen angeben, was mit den
                    Unterstützungen passiert. Projekte unternehmerischer Natur
                    erhalten die Unterstützungen ausschließlich bei Erreichen
                    des Fundingziels, ansonsten werden die Gelder jeweils an die
                    Unterstützer zurückgezahlt.
                  </p>
                </div>
              </div>
              <h2>Wir vertrauen der Community!</h2>
              <SvgWrapper width={isMobile() ? '100%' : '350px'} height="auto">
                <ConnectedIcon />
              </SvgWrapper>
              <p>
                Wir sind auf unsere Community angewiesen! Um Lücken zu
                schließen, die wir möglicherweise übersehen haben oder externen
                und unzugänglichen Informationen nachzugehen, benötigen wir eure
                Hilfe. Bitte wendet euch bei Auffälligkeiten an unser Team unter{' '}
                {''}
                <a href="mailto: support@commonsplace.de">
                  support@commonsplace.de
                </a>
              </p>
            </div>
          </FeesPageWrapper>
        </Container>
      </BaseLayout>
    </div>
  );
}
