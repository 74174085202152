import React from 'react';
import { Colors, device } from 'utils';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CardWrapper = styled.article`
  background: ${({ isMatched }) =>
    isMatched ? Colors.secondaryColor : Colors.white};
  border-radius: 10px;
  border: 2px solid ${Colors.steel};
  cursor: pointer;
  max-width: 350px;
  margin: auto;
  height: 100%;
  position: relative;
  @media ${device.laptop} {
    max-width: 280px;
  }
  &:hover {
    border: 2px solid ${Colors.secondaryColor};
  }
  & > a {
    margin: 0;
    color: ${Colors.black};
    &:hover {
      border-bottom: none;
    }
  }
  .card {
    &__img-wrapper {
      position: relative;
      max-height: 200px;
      border-radius: 10px 10px 0 0;
      @media ${device.laptop} {
        max-height: 150px;
      }
      & > span,
      img {
        width: 100%;
        height: 200px;
        position: relative;
        display: inline-block;
        background: ${Colors.grey};
        border-radius: 10px 10px 0 0;
        object-fit: cover;
        @media ${device.laptop} {
          max-height: 150px;
        }
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
    &__category {
      color: ${Colors.white};
      text-transform: uppercase;
      font-size: 12px;
      background-color: rgba(42, 42, 42, 0.8);
      padding: 5px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      opacity: 0.8;
    }
    &__form {
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      color: ${Colors.black};
      text-transform: uppercase;
      font-size: 12px;
      background: ${Colors.white};
      padding: 5px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      opacity: 0.5;
      @media ${device.laptop} {
        font-size: 10px;
      }
    }
    &__content {
      padding: 10px 20px;
      footer {
        display: flex;
        justify-content: space-between;
        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 1;
          margin-top: 5px;
          @media ${device.laptop} {
            font-size: 12px;
          }
          span {
            margin-right: 5px;
          }
        }
      }
    }
    &__name {
      margin: 0;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
      min-height: 56px;
      @media ${device.laptop} {
        font-size: 16px;
      }
    }
    &__description {
      font-size: 16px;
      margin: 0;
      width: 100%;
      min-height: 100px;
      overflow-y: hidden;
      overflow-x: hidden;
      margin-bottom: 45px;
      @media ${device.laptop} {
        font-size: 14px;
        min-height: 75px;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    p {
      font-weight: bold;
    }
  }

  .limit {
    word-wrap: break-word;
  }
`;

const CategoryItemContainer = styled.div`
  background: ${({ isMatched }) =>
    isMatched ? Colors.secondaryColor : Colors.white};
  border-radius: 10px;
  border: 2px solid ${Colors.secondaryColor} !important;
  // color: white !important;
  cursor: pointer;
  width: 150px;
  font-size: 18px;
  border: 1px solid;
  text-align: center;
  padding: 5px;
  border-radius: 8px;

  &:hover{
    border: 2px solid ${Colors.secondaryColor} !important;
    
    background: ${({ isMatched }) =>
    isMatched ? Colors.white : Colors.secondaryColor} !important;
    h2{
    color: ${({ isMatched }) =>
    isMatched ? Colors.secondaryColor : Colors.white} !important;}
  }
`;

export default function ProjectCategoryCard(props) {
  const {
    project,

    onClick,
    url = '/',
    isMatched,
  } = props;

  return (
    <CategoryItemContainer isMatched={isMatched}>
      <div onClick={() => onClick(project)}>
        {/* <Link
          to={{
            pathname: `${url}${category.toLowerCase()}`,
            state: { data: data },
          }}
        > */}
        <h2
          title={project?.category || ''}
          style={{
            fontSize: '14px',
            margin: '0',
            color: isMatched ? 'white' : Colors.secondaryColor,
          }}
        >
          {project?.category}
        </h2>
        {/* </Link> */}
      </div>
    </CategoryItemContainer>
  );
}

ProjectCategoryCard.propTypes = {
  project: PropTypes.object.isRequired,
};
