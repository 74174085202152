import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import PropTypes from 'prop-types';

import { Colors, device, isMobile } from 'utils';
import styles from './sideMenu.module.css';
import { useSelector } from 'react-redux';

const insetIn = keyframes`
  0% { right: -315px; }
  100% { right: 0; }
`;

const insetOut = keyframes`
  0% { right: 0; }
  100% { right: -315px; }
`;

const fillWidth = keyframes`
  0% { 
    width: 0;
    background: ${Colors.secondaryColor};
  }
  100% { 
    width: 100%;
    background: ${Colors.primaryColor};
  }
`;

const openCollapse = keyframes`
  0% { 
    max-height: 1px;
    overflow: hidden;
  }
  100% {
    max-height: 1000px;
    overflow: visible;
  }
`;

const closeCollapse = keyframes`
  0% { 
    max-height: 1000px;
    overflow: visible;
  }
  100% { 
    max-height: 1px;
    overflow: hidden;
  }
`;

const rotateIn = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const rotateOut = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const BurgerMenu = styled.div`
  position: fixed;
  right: -315px;
  top: 0;
  bottom: 0;
  background: ${Colors.white};
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 2);
  padding: 20px;
  min-width: 300px;
  min-height: 100vh;
  animation: ${insetOut} 0.5s forwards;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.open-menu {
    animation: ${insetIn} 0.5s forwards;
    overflow: auto;
  }
  ul {
    padding: 0;
    li,
    .collapse-title {
      display: block;
      //   padding-bottom: 10px;
      padding-top: 10px;
      cursor: pointer;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      a {
        display: block;
      }
      //   &:before {
      //     content: '';
      //     display: inline-block;
      //     position: absolute;
      //     width: 100%;
      //     height: 2px;
      //     background: ${Colors.secondaryColor};
      //     bottom: 0;
      //     left: 50%;
      //     transform: translateX(-50%);
      //   }
      &:hover {
        &:before {
          animation: ${fillWidth} 0.5s forwards;
        }
      }
      &.home {
        padding: 0;
        border-bottom: none;
        a {
          text-align: center;
          @media ${device.allMobile} {
            padding: 5px;
          }
        }

        img {
          width: 200px;
        }
        &:before {
          content: none !important;
        }
      }
      &.start-project {
        border-bottom: none;
        cursor: pointer;
        button {
          margin-bottom: 0;
          @media ${device.allMobile} {
            padding: 5px;
          }
        }
        &:before {
          content: none !important;
        }
      }
    }
    ul {
      padding-left: 15px;
    }
    .ul-close {
      animation: ${closeCollapse} 0.3s forwards;
    }
    .ul-open {
      animation: ${openCollapse} 0.3s forwards;
    }
    .drop-down {
      display: flex;
      justify-content: space-between;
    }
    .li-open {
      .arrow-down {
        animation: ${rotateIn} 0.2s forwards;
      }
    }
    .li-close {
      .arrow-down {
        animation: ${rotateOut} 0.2s forwards;
      }
    }
  }
`;

const BackGroundWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0.5;
  z-index: 2;
`;
const CloseIcon = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9;
  display: inline-block;
  width: 65px;
  height: 65px;
  transform: rotate(0);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media ${device.allMobile} {
    width: 57px;
    height: 57px;
  }
  span {
    direction: lrt;
    display: block;
    position: absolute;
    width: 100%;
    background: ${Colors.primaryColor};
    border-radius: 9px;
    opacity: 1;
    right: 10px;
    transform: rotate(0);
    transition: 0.25s ease-in-out;
    height: 4px;
    &:nth-of-type(1) {
      top: 32px;
      transform: rotate(135deg);
      width: 22px;
      right: 15px;
      background: ${Colors.secondaryColor};
      @media ${device.allMobile} {
        width: 26px;
        top: 30px;
        right: 15px;
      }
    }

    &:nth-of-type(2) {
      background: ${Colors.secondaryColor};
      top: 32px;
      width: 22px;
      transform: rotate(-135deg);
      right: 15px;
      @media ${device.allMobile} {
        width: 26px;
        top: 30px;
        right: 15px;
      }
    }
  }
`;

const CategoryMenu = ({
  openMenu,
  handleToggleMenu,
  closeMenu,
  onClick,
  selected,
}) => {
  const categories = useSelector((state) => state.projects.categories);
  const old_categories = categories.filter((el) => el.new_item === false);
  const new_categories = categories.filter((el) => el.new_item === true);
  return (
    <>
      {closeMenu !== null && (
        <>
          {openMenu && (
            <>
              <BackGroundWrapper onClick={handleToggleMenu} />
              <CloseIcon onClick={handleToggleMenu}>
                <span></span>
                <span></span>
              </CloseIcon>
            </>
          )}

          <BurgerMenu
            className={openMenu ? 'menu-icon open-menu' : 'menu-icon'}
          >
            <nav className="header-nav">
              <ul>
                {isMobile() && (
                  <>
                    <h3
                      style={{
                        textAlign: 'left',
                        marginTop: '1rem',
                        marginBottom: '0',
                      }}
                    >
                      Kategorie
                    </h3>
                    {old_categories.map((el, idx) => (
                      <li
                        key={idx}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        className={`category ${
                          el.category.toLowerCase() === selected?.toLowerCase()
                            ? 'active-category'
                            : ''
                        }`}
                      >
                        <div
                          onClick={() => {
                            onClick(el);
                            handleToggleMenu();
                          }}
                        >
                          {el.category}
                        </div>
                      </li>
                    ))}

                    {new_categories.length > 0 && (
                      <h3
                        style={{
                          marginTop: '1rem',
                          marginBottom: '0',
                          textAlign: 'left',
                          textTransform: 'capitalize',
                        }}
                      >
                        Weitere Themen
                      </h3>
                    )}
                    <ul style={{ paddingLeft: 0 }}>
                      {new_categories.map((el, idx) => (
                        <li
                          key={idx}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                          className={`category ${
                            el.category.toLowerCase() ===
                            selected?.toLowerCase()
                              ? 'active-category'
                              : ''
                          }`}
                        >
                          <div
                            onClick={() => {
                              onClick(el);
                              handleToggleMenu();
                            }}
                          >
                            {el.category}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </ul>
            </nav>
          </BurgerMenu>
        </>
      )}
    </>
  );
};

CategoryMenu.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  handleToggleMenu: PropTypes.func.isRequired,
};

export default CategoryMenu;
