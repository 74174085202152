import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router';

const StripeApplePay = ({ userData, clientSecret, totalAmount }) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const history = useHistory();
    useEffect(() => {
        const setupPaymentRequest = async () => {
            if (stripe && totalAmount && userData && clientSecret && totalAmount) {
                const pr = stripe.paymentRequest({
                    country: 'DE',
                    currency: 'eur',
                    total: {
                        label: userData.email,
                        amount: totalAmount,
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                });

                const result = await pr.canMakePayment();
                // console.log('result', result);

                if (result) {
                    setPaymentRequest(pr);

                    pr.on('paymentmethod', async (ev) => {
                        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                            clientSecret,
                            { payment_method: ev.paymentMethod.id },
                            { handleActions: false }
                        );

                        if (confirmError) {
                            ev.complete('fail');
                        } else {
                            ev.complete('success');
                            if (paymentIntent.status === "requires_action") {
                                const { error } = await stripe.confirmCardPayment(clientSecret);
                                if (error) {
                                    // Handle the case where the payment fails
                                    console.log("Error: ", error);
                                } else {
                                    // Handle the case where the payment is successful
                                    history.push('/thank-you-page');
                                }
                            } else {
                                // Handle the case where the payment is successful
                                history.push('/thank-you-page');
                            }
                        }
                    });
                }
            }
        };

        setupPaymentRequest();
    }, [stripe, clientSecret, totalAmount, userData]);
    // console.log('paymentRequest', { paymentRequest, userData, totalAmount, stripe, clientSecret });

    if (paymentRequest) {
        return <PaymentRequestButtonElement options={{ paymentRequest }} />;
    } else {
        return null;
    }
};

export default StripeApplePay;
