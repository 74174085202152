import React, { Component } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LoginForm } from 'components';
import { Modal } from 'utils';
import {
  Logo,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkedIn,
} from 'images';
import {
  Colors,
  Container,
  PrimaryButton,
  GreenButton,
  device,
  isLabTop,
  isTablet,
  SvgWrapper,
  isMobile,
} from 'utils';
import { authActions } from 'redux/actions';
import { ProjectsActions } from 'redux/actions';
import isEmpty from 'loadsh/isEmpty';
import { withRouter } from 'react-router-dom';
import SideMenu from './sideMenu';

const insetIn = keyframes`
  0% { left: -315px; }
  100% { left: 0; }
`;

const appearedIn = keyframes`
  0% { 
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
`;
const insetOut = keyframes`
  0% { left: 0; }
  100% { left: -315px; }
`;

const fillWidth = keyframes`
  0% { 
    width: 0;
    background: ${Colors.secondaryColor};
  }
  100% { 
    width: 100%;
    background: ${Colors.primaryColor};
  }
`;

const openCollapse = keyframes`
  0% { 
    max-height: 1px;
    overflow: hidden;
  }
  100% {
    max-height: 1000px;
    overflow: visible;
  }
`;

const closeCollapse = keyframes`
  0% { 
    max-height: 1000px;
    overflow: visible;
  }
  100% { 
    max-height: 1px;
    overflow: hidden;
  }
`;

const rotateIn = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const rotateOut = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const BurgerIcon = styled.div`
  width: 65px;
  height: 65px;
  position: relative;
  transition: 0.5s ease-in-out;
  transform: rotate(180deg);
  cursor: pointer;
  top: 0;
  right: 0;
  z-index: 2;
  @media ${device.allMobile} {
    width: 57px;
    height: 57px;
  }
  span {
    direction: lrt;
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: ${Colors.secondaryColor};
    border-radius: 9px;
    opacity: 1;
    right: 10px;
    transform: rotate(0);
    transition: 0.25s ease-in-out;
    @media ${device.allMobile} {
      right: 15px;
    }
    &:nth-of-type(1) {
      top: 25px;
      width: 32px;
      @media ${device.allMobile} {
        width: 20px;
        top: 20px;
      }
    }
    &:nth-of-type(2) {
      top: 35px;
      width: 42px;
      @media ${device.allMobile} {
        width: 26px;
        top: 26px;
      }
    }
    &:nth-of-type(3) {
      top: 45px;
      width: 32px;
      @media ${device.allMobile} {
        width: 20px;
        top: 32px;
      }
    }
  }
  &.open {
    span {
      height: 4px;
      &:nth-of-type(1) {
        top: 32px;
        transform: rotate(135deg);
        width: 22px;
        right: 15px;
        background: ${Colors.secondaryColor};
        @media ${device.allMobile} {
          width: 26px;
          top: 30px;
          right: 15px;
        }
      }
      &:nth-of-type(2) {
        opacity: 0;
        right: 60px;
      }
      &:nth-of-type(3) {
        background: ${Colors.secondaryColor};
        top: 32px;
        width: 22px;
        transform: rotate(-135deg);
        right: 15px;
        @media ${device.allMobile} {
          width: 26px;
          top: 30px;
          right: 15px;
        }
      }
    }
  }
`;
const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 20px;
  @media ${device.laptop} {
    padding-left: 0;
  }

  z-index: 2;
  & > div {
    width: 33.33%;
    &.logo {
      width: 32.66%;
      & + div {
        width: 34%;
      }
    }
  }
  @media ${device.allMobile} {
    padding: 0 10px;
  }
  img {
    width: 150px;
  }
  h1 {
    display: flex;
    align-items: center;
  }
  ul {
    display: inline-block;
    &.main-nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 15px 0;
      @media ${device.allMobile} {
        display: none;
      }
      li {
        line-height: 1;
        button {
          margin-bottom: 0;
        }
      }
    }
    li {
      display: inline-block;
      color: ${Colors.white};
      & + li {
        margin-left: 15px;
      }
    }
  }
`;
const BurgerMenu = styled.div`
  position: fixed;
  left: -315px;
  top: 0;
  bottom: 0;
  background: ${Colors.white};
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 2);
  padding: 20px;
  min-width: 300px;
  min-height: 100vh;
  animation: ${insetOut} 0.5s forwards;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.open-menu {
    animation: ${insetIn} 0.5s forwards;
    overflow: auto;
  }
  ul {
    padding: 0;
    li,
    .collapse-title {
      display: block;
      padding-bottom: 10px;
      padding-top: 10px;
      cursor: pointer;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      a {
        display: block;
      }
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: ${Colors.secondaryColor};
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      &:hover {
        &:before {
          animation: ${fillWidth} 0.5s forwards;
        }
      }
      &.home {
        padding: 0;
        border-bottom: none;
        a {
          text-align: center;
          @media ${device.allMobile} {
            padding: 5px;
          }
        }

        img {
          width: 200px;
        }
        &:before {
          content: none !important;
        }
      }
      &.start-project {
        border-bottom: none;
        cursor: pointer;
        button {
          margin-bottom: 0;
          @media ${device.allMobile} {
            padding: 5px;
          }
        }
        &:before {
          content: none !important;
        }
      }
    }
    ul {
      padding-left: 15px;
    }
    .ul-close {
      animation: ${closeCollapse} 0.3s forwards;
    }
    .ul-open {
      animation: ${openCollapse} 0.3s forwards;
    }
    .drop-down {
      display: flex;
      justify-content: space-between;
    }
    .li-open {
      .arrow-down {
        animation: ${rotateIn} 0.2s forwards;
      }
    }
    .li-close {
      .arrow-down {
        animation: ${rotateOut} 0.2s forwards;
      }
    }
  }
`;
const SocialMedia = styled.ul`
  bottom: 10px;
  display: flex;
  width: calc(100% - 40px);
  justify-content: space-around;
  padding: 0 !important;
  @media ${device.allMobile} {
    position: relative;
    bottom: 0;
    margin-top: 15px;
  }
  li:before {
    content: none !important;
  }
`;
const BackGroundWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0.5;
  z-index: 2;
`;
const CloseIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3;
  display: inline-block;
  width: 65px;
  height: 65px;
  transform: rotate(0);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media ${device.allMobile} {
    width: 57px;
    height: 57px;
  }
  span {
    direction: lrt;
    display: block;
    position: absolute;
    width: 100%;
    background: ${Colors.primaryColor};
    border-radius: 9px;
    opacity: 1;
    right: 10px;
    transform: rotate(0);
    transition: 0.25s ease-in-out;
    height: 4px;
    &:nth-of-type(1) {
      top: 32px;
      transform: rotate(135deg);
      width: 22px;
      right: 15px;
      background: ${Colors.secondaryColor};
      @media ${device.allMobile} {
        width: 26px;
        top: 30px;
        right: 15px;
      }
    }

    &:nth-of-type(2) {
      background: ${Colors.secondaryColor};
      top: 32px;
      width: 22px;
      transform: rotate(-135deg);
      right: 15px;
      @media ${device.allMobile} {
        width: 26px;
        top: 30px;
        right: 15px;
      }
    }
  }
`;
const HeaderWrapper = styled.header`
  overflow-x: hidden;
  position: absolute;
  max-width: 100vw;
  background: transparent;
  top: 0;
  width: 100%;

  z-index: 3;
  .disktop-only {
    @media ${device.allMobile} {
      display: none;
    }
  }
  &.header-wrapper {
    position: fixed;
  }
  &.relative-header {
    position: relative;
    background: ${Colors.transparent};
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    ul {
      li {
        color: ${Colors.black};
      }
    }

    .start-project {
      color: ${Colors.secondaryColor};
    }
  }

  &.white-background {
    position: fixed;
    background: ${Colors.white};
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    animation: ${appearedIn} 0.5s forwards;
    li {
      color: ${Colors.black};
    }
    .start-project {
      color: ${Colors.secondaryColor};
    }
  }
  .burger-menu {
    padding: 0;
    margin: 0;
  }
  .start-project {
    color: ${Colors.primaryColor};
  }
  .logout {
    button {
      width: 100%;
      background: none;
      border: none;
      text-align: left;
      padding: 0;
    }
  }
  .center-menu {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    li {
      margin: auto;
    }
  }
`;
class Header extends Component {
  static propTypes = {
    relativeHeader: PropTypes.bool,
    showLogin: PropTypes.func,
    user: PropTypes.object,
    whiteHeader: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    mainProfile: PropTypes.bool,
  };
  componentDidMount() {
    const {
      getProjectCategories,
      categories,
      match: {
        params: { categoryName },
      },
      whiteHeader,
    } = this.props;
    !categories.length && getProjectCategories();

    const header = document.getElementById('main-header');

    if (!whiteHeader) {
      if (categoryName) {
        header.classList.add('white-background');

        header.style.position = 'relative';
      } else
        window.addEventListener('scroll', function () {
          if (window.scrollY > 50) {
            header.classList.add('white-background');
          } else {
            header.classList.remove('white-background');
          }
        });
    } else {
      return;
    }
  }
  state = {
    closeMenu: null,
    openMenu: false,
    openHelp: false,
    openContactUs: false,
    faceBookHover: false,
    twitterHover: false,
    instagramHover: false,
    youTubeHover: false,
    showModal: false,
  };

  handleToggleMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu,
      closeMenu: this.state.openMenu ? true : false,
    });
    if (this.state.openMenu) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  handleToggleFacebookHover = () => {
    this.setState({ faceBookHover: !this.state.faceBookHover });
  };

  handleToggleYouTubeHover = () => {
    this.setState({ youTubeHover: !this.state.youTubeHover });
  };

  handleToggleInstagramHover = () => {
    this.setState({ instagramHover: !this.state.instagramHover });
  };

  handleToggleTwitterHover = () => {
    this.setState({ twitterHover: !this.state.twitterHover });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
  };
  handleClick = () => {
    const client = localStorage.getItem('client');
    const uid = localStorage.getItem('uid');
    const access = localStorage.getItem('access-token');
    const user = localStorage.getItem('user');
    const isLoggedIn =
      user && !isEmpty(user) && access && uid && client ? true : false;
    isLoggedIn
      ? this.props.history.push('/start-project')
      : this.props.history.push('/login');
  };

  render() {
    const {
      match: {
        params: { categoryName },
      },
    } = this.props;

    const {
      openMenu,
      twitterHover,
      instagramHover,
      youTubeHover,
      faceBookHover,
      closeMenu,
    } = this.state;
    const {
      relativeHeader,
      whiteHeader,
      // user,
      logout,
      mainProfile,
    } = this.props;
    const { categories } = this.props;

    const client = localStorage.getItem('client');
    const uid = localStorage.getItem('uid');
    const access = localStorage.getItem('access-token');
    const user = localStorage.getItem('user');
    const userType = localStorage.getItem('user-type');
    const isLoggedIn =
      user && !isEmpty(user) && access && uid && client ? true : false;
    const headerClass = `${
      relativeHeader ? 'header-wrapper relative-header ' : 'header-wrapper'
    } ${whiteHeader ? 'white-background' : ''}`;

    return (
      <>
        <HeaderWrapper id="main-header" className={headerClass}>
          <Container>
            <MainHeader className="main-header">
              <div>
                <ul className="burger-menu">
                  <li>
                    <BurgerIcon
                      onClick={this.handleToggleMenu}
                      className={openMenu ? 'open' : 'close'}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </BurgerIcon>
                  </li>
                </ul>
              </div>
              <div className="logo">
                <ul className="center-menu">
                  <li>
                    <h1>
                      <Link to="/">
                        <span className="visually-hidden">CrowdFunding</span>
                        <img src={Logo} alt="crowd-funding-logo-icon" />
                      </Link>
                    </h1>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="main-nav">
                  {!isTablet() && (
                    <li>
                      <PrimaryButton
                        primary={false}
                        onClick={this.handleClick}
                        minWidth={isLabTop() ? '100px' : '150px'}
                      >
                        Projekt starten
                      </PrimaryButton>
                    </li>
                  )}

                  <li>
                    {isLoggedIn ? (
                      <>
                        <Link to="/profile">
                          {mainProfile ? (
                            <GreenButton
                              primary={true}
                              minWidth={isLabTop() ? '100px' : '150px'}
                            >
                              Mein Profil
                            </GreenButton>
                          ) : (
                            <PrimaryButton
                              primary={true}
                              minWidth={isLabTop() ? '100px' : '150px'}
                            >
                              Mein Profil
                            </PrimaryButton>
                          )}
                        </Link>
                      </>
                    ) : (
                      <PrimaryButton
                        onClick={() => this.setState({ showModal: true })}
                        primary={true}
                        minWidth="150px"
                      >
                        Anmelden
                      </PrimaryButton>
                    )}
                  </li>
                </ul>
              </div>
            </MainHeader>
          </Container>
          <SideMenu
            handleToggleMenu={this.handleToggleMenu}
            openMenu={openMenu}
            closeMenu={closeMenu}
          />
          <Modal
            showModal={isLoggedIn ? false : this.state.showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <LoginForm />
          </Modal>
        </HeaderWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  const { categories } = state.projects;
  return {
    user,
    categories,
  };
};
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
  getProjectCategories: () => dispatch(ProjectsActions.getProjectCategories()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
