const prod = {
  REACT_APP_API_URL: 'https://api.commonsplace.de/',
  REACT_APP_URL: 'https://commonsplace.de/',
  REACT_APP_STRIPE_KEY:
    'pk_live_51IT2JSD2k6MFI1d2ZtM5bNORjSIaAKRcR7zfJOKZcgTXlNrLSrtIyD2RjTfv3Sa7i3Baleq18awoUoEmycYwM3cr00prwA819s',
};

const dev = {
  REACT_APP_API_URL: 'https://api-test.commonsplace.de/',
  REACT_APP_URL: 'https://testing.commonsplace.de/',
  REACT_APP_STRIPE_KEY:
    'pk_test_51IT2JSD2k6MFI1d2Im5KrNVNGgSnY2Tj94u4XPUYFUiSv8leQrJlFGO9F5uWnUp39qOuPErAD0D0RaQipRAPsZVk00VeLlc9vT',
};

console.log('ENV -->', process.env.REACT_APP_STAGE);
const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default {
  // Add common config values here
  ...config,
};
