import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { BaseLayout, Card, ProjectCategoryCard } from 'components';
import { Cross, ShortBackground, SuccessProject } from 'images';
import {
  Colors,
  H1,
  device,
  Container,
  isMobile,
  generateProjectsArray,
} from 'utils';
import { ProjectsActions } from 'redux/actions';
import { Spin } from 'antd';
import SideMenu from 'components/layout/sideMenu';
import CategoryMenu from 'components/layout/categoryMenu';
import { Helmet } from 'react-helmet';
import { adminServices } from 'services';

const Intro = styled.div`
  text-align: center;
  h1 {
    font-size: 55px;
    @media ${device.allMobile} {
      font-size: 30px;
    }
  }
`;
const TableWrapper = styled.div`
  padding: 50px 0;
`;
const ProjectWrapper = styled.div`
  .content-Wrapper {
    max-width: 950px;
    margin: auto;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background: ${Colors.white};
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 30px 40px;
  @media ${device.allMobile}, ${device.tablet} {
    padding: 0;
    background: ${Colors.transparent};
    box-shadow: none;
    border-radius: 0;
  }
  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;

    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
    @media ${device.minLaptop} {
      width: 48%;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 0;
    }
    &:nth-of-type(3n + 2) {
      // margin: 0 15px 20px;
      @media ${device.tablet} {
        margin: 0 14px 20px;
      }
      @media ${device.allMobile} {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
      }
      @media ${device.minLaptop} {
        margin: auto;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

const RelatedProjectsWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  h1 {
    text-align: left;
    font-size: 30px;
    color: ${Colors.secondaryColor};
    margin: 0;
    @media ${device.allMobile} {
      text-align: center;
      font-size: 24px;
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  & > button {
    background: none;
    border: none;
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
    &:hover {
      border-bottom: 2px solid ${Colors.secondaryColor};
    }
  }
`;
const SuccessfulProjectsWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  @media ${device.allMobile} {
    display: block;
  }
  .card {
    width: 31%;
    margin: 1%;
    display: inline-block;
    text-align: center;
    margin-bottom: 65px;
    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
`;
const SuccessfulWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  & > button {
    background: none;
    border: none;
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
    &:hover {
      border-bottom: 2px solid ${Colors.secondaryColor};
    }
  }
`;
const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
  column-gap: 40px;
  row-gap: 20px;

  > * {
    flex-grow: 1;
    max-width: calc(20% - 40px);
    flex-basis: calc(20% - 40px);
  }

  > :nth-child(9n + 6),
  > :nth-child(9n + 7),
  > :nth-child(9n + 8),
  > :nth-child(9n + 9) {
    flex-basis: calc(25% - 40px);
    max-width: calc(25% - 40px);
  }
`;
const CategoryChip = styled.div`
  position: relative;
  display: inline-flex;
  gap: 3px;
  padding: 2px 6px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: 20px;
  // z-index: 2;
  align-self: center;
  font-size: 14px;
`;

const FilterBtn = styled.div`
  position: relative;
  display: inline-flex;
  gap: 3px;
  padding: 2px 6px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: 20px;
  align-self: center;
  font-size: 14px;
  background-color: #95bd51;
  color: #fff;
  border: none;

  img {
    filter: invert(1);
  }

  &:hover {
    border: 1px solid #95bd51;
    background-color: transparent;
    color: #95bd51;
    & img {
      filter: invert(0);
    }
  }
`;

export class ProjectsPage extends Component {
  componentDidMount = () => {
    const { getProjectCategories, categories, tempCategory } = this.props;
    if (tempCategory?.category) {
      this.setState({ selectedCategory: tempCategory });
    }
    categories.length === 0 && getProjectCategories();
    this.handleShowProjects();
    this.handleShowSuccessfulProjects();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.projects !== this.props.projects) {
      const cominedArr = this.state.allProjects.concat(
        this.props.projects.projects
      );
      const previousIds = cominedArr?.map((project) => project.id);
      this.setState({ allProjects: cominedArr });
      this.setState({ allProjectsIds: previousIds });
    }

    if (prevProps.successfulProjects !== this.props.successfulProjects) {
      const cominedArr = this.state.allSuccessfullProjects.concat(
        this.props.successfulProjects.projects
      );
      const previousIds = cominedArr?.map((project) => project.id);
      this.setState({ allSuccessfullProjects: cominedArr });
      this.setState({ allSuccessfullProjectsIds: previousIds });
    }

    if (
      
      prevState.selectedCategory?.category !==
      this.state.selectedCategory?.category
    ) {
      this.getProjectById();
      if(this.state.selectedCategory?.category) this.props.getAllSuccesfullEvents(this.state.selectedCategory?.category)
    }
  };
  
  static propTypes = {
    projects: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    getProjects: PropTypes.func.isRequired,
    getSuccessfulProjects: PropTypes.func.isRequired,
    successfulProjects: PropTypes.object.isRequired,
  };
  state = {
    showMore: true,
    perPage: 0,
    successfulPerPage: 0,
    allProjects: [],
    allProjectsIds: [],
    allSuccessfullProjects: [],
    allSuccessfullProjectsIds: [],
    closeMenu: null,
    openMenu: false,
    selectedCategory: '',
    loading: false,
    filteredProjects: [],
  };
  handleShowProjects = () => {
    const { getProjects } = this.props;
    const { perPage, allProjectsIds } = this.state;
    getProjects(perPage + 6, 1, allProjectsIds);
    this.setState({ perPage: perPage + 6 });
  };
  handleShowSuccessfulProjects = () => {
    const { getSuccessfulProjects } = this.props;
    const { successfulPerPage, allSuccessfullProjectsIds } = this.state;
    getSuccessfulProjects(successfulPerPage + 6, 1, allSuccessfullProjectsIds);
    this.setState({ successfulPerPage: successfulPerPage + 6 });
  };

  handleToggleMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu,
      closeMenu: this.state.openMenu ? true : false,
    });
    const header = document.querySelector('header#main-header');
    const burger = header?.querySelector('ul.burger-menu');

    if (this.state.openMenu) {
      document.body.style.overflow = 'unset';
      if (burger) burger.style.display = 'block';
    } else {
      document.body.style.overflow = 'hidden';
      if (burger) burger.style.display = 'none';
    }
  };

  async getProjectById() {
    if (!this.state.selectedCategory) return;
    let type = 'projects';
    try {
      this.setState({ loading: true });
      const res = await adminServices.getCategoryBasedProjects(
        this.state.selectedCategory?.category || '',
        true,
        type
      );
      const data = generateProjectsArray(res?.projects);
      this.setState({ filteredProjects: data });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      perPage,
      successfulPerPage,
      allProjects,
      allSuccessfullProjects,
      selectedCategory,
      loading: projectsLoading,
      filteredProjects,
    } = this.state;
    const { projects, loading, successfulProjects, categories, allSuccessfullEvents } = this.props;
    const combinedProjects = selectedCategory.category
      ? filteredProjects
      : allProjects;

    const combinedSuccessfullProjects= selectedCategory.category ?allSuccessfullEvents?.projects : allSuccessfullProjects;

    const showSuccessfull= true;

    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Innovative deutsch-muslimische Projekte auf commonsplace unterstützen und entdecken. Teil einer engagierten Gemeinschaft werden und mitmachen."
          />
        </Helmet>
        <ProjectWrapper>
          <BaseLayout
            maxHeightProps="500px"
            maxHeightMobileProps="250px"
            container={false}
            img={ShortBackground}
            bannerComponent={
              <Intro>
                <H1>{'Entdecke großartige Projekte'}</H1>
              </Intro>
            }
          >
            <Container>
              <>
                <CategoryMenu
                  openMenu={this.state.openMenu}
                  handleToggleMenu={this.handleToggleMenu}
                  closeMenu={this.state.closeMenu}
                  onClick={(category) => {
                    this.setState({ selectedCategory: category });
                  }}
                  selected={selectedCategory?.category}
                />
                {isMobile() && (
                  <>
                    <CategoryChip
                      onClick={this.handleToggleMenu}
                      style={{
                        backgroundColor: '#95bd51',
                        color: '#fff',
                        border: 'none',
                      }}
                    >
                      Filter zeigen
                    </CategoryChip>
                    {selectedCategory && (
                      <FilterBtn style={{ marginLeft: '10px' }}>
                        <span>{selectedCategory.category}</span>
                        <div>
                          <div
                            style={{ height: '18px', marginTop: '-1px' }}
                            onClick={() => {
                              this.setState({ selectedCategory: '' });
                            }}
                          >
                            <img
                              style={{ height: '100%', }}
                              src={Cross}
                              alt=""
                            />
                          </div>
                        </div>
                      </FilterBtn>
                    )}
                  </>
                )}
                <RelatedProjectsWrapper>
                  
                  <Spin spinning={loading || projectsLoading}>
                    {!isMobile() && (
                      <CategoryContainer>
                        {categories &&
                          categories.length > 0 &&
                          categories.map((project, index) => (
                            <ProjectCategoryCard
                              project={project}
                              key={`${JSON.stringify(project)}-${index}`}
                              data={categories}
                              onClick={(category) => {
                                this.setState({ selectedCategory: category });
                              }}
                              isMatched={
                                selectedCategory.category === project.category
                              }
                            />
                          ))}
                      </CategoryContainer>
                    )}
                    <h1 style={{ marginBottom: '20px' }}>Jetzt unterstützen</h1>
                    {
                      <CardsWrapper>
                        {combinedProjects && combinedProjects.length > 0 ? (
                          combinedProjects.map((project, index) => (
                            <Card
                              project={project}
                              key={`${JSON.stringify(project)}-${index}`}
                            />
                          ))
                        ) : (
                          <>No project found</>
                        )}
                      </CardsWrapper>
                    }
                  </Spin>
                  {!selectedCategory && perPage < projects.count && (
                    <button
                      className="show-more"
                      onClick={() => this.handleShowProjects()}
                    >
                      Mehr anzeigen
                    </button>
                  )}
                </RelatedProjectsWrapper>

                {showSuccessfull &&
                  combinedSuccessfullProjects &&
                  combinedSuccessfullProjects.length > 0 && (
                    <SuccessfulWrapper>
                      <div>
                        <H1>Erfolgreiche Projekte</H1>
                      </div>
                      <Spin spinning={loading}>
                        <SuccessfulProjectsWrapper>
                          {combinedSuccessfullProjects.length > 0 &&
                            combinedSuccessfullProjects.map((project, index) => (
                              <Card
                                project={project}
                                key={`${JSON.stringify(project)}-${index * 33}`}
                              />
                            ))}
                        </SuccessfulProjectsWrapper>
                      </Spin>
                      {successfulPerPage < successfulProjects.count && (
                        <button
                          className="show-more"
                          onClick={() => this.handleShowSuccessfulProjects()}
                        >
                          Mehr anzeigen
                        </button>
                      )}
                    </SuccessfulWrapper>
                  )}
              </>
            </Container>
          </BaseLayout>
        </ProjectWrapper>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    projects,
    loading,
    errors,
    successfulProjects,
    categories,
    tempCategory,
    allSuccessfullEvents
  } = state.projects;
  return {
    projects,
    loading,
    errors,
    successfulProjects,
    categories,
    tempCategory,
    allSuccessfullEvents
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjects: (perPage, page, ids) =>
    dispatch(ProjectsActions.getProjects(perPage, page, ids)),
  getSuccessfulProjects: (perPage, page, ids) =>
    dispatch(ProjectsActions.getSuccessfulProjects(perPage, page, ids)),
  getProjectCategories: () => dispatch(ProjectsActions.getProjectCategories()),
  getAllSuccesfullEvents: (cat) => dispatch(ProjectsActions.getAllSuccessfullEvent(cat)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);
