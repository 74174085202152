import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import { AdminActions, ProjectsActions } from 'redux/actions';
import { Layout } from 'components';
import { Row, Col, Input, Form, Radio, Select, Spin } from 'antd';
import { Container, H1, H2, Modal, PrimaryButton, device, Colors } from 'utils';
// import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import moment from 'moment';
import ProjectFAQs from './ProjectFAQs';
import { UploadImage } from 'components';
import { projectsServices } from 'services';
import '../ProjectForm/components/RichTextCss.css';
import '../Project/components/project_details.css';

const Option = Select.Option;
const FormItem = Form.Item;
const TableWrapper = styled.div`
  padding: 50px 0;
  h3,
  p {
    margin: 0;
  }
  h2 {
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .ant-row {
    align-items: center;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  button {
    margin-right: 20px;
  }
  .total-decline {
    background: #f44336;
    border: 1px solid #f44336;
    &:hover {
      background: ${Colors.transparent};
      border: 1px solid #f44336;
      color: #f44336;
    }
  }
`;

const ModalWrapper = styled.div`
  max-height: 80vh;
  wight: 960px;
  .images {
    overflow-y: scroll;
    max-height: 200px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    align-items: center;
    img {
      width: 100px;
      height: auto;
      margin-right: 15px;
      cursor: pointer;
      margin-top: 10px;
      @media ${device.allMobile} {
        width: 28%;
      }
    }
  }
  .selected-image {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${device.allMobile} {
      height: auto;
    }
    img {
      width: 100%;
      height: auto;
      max-height: 550px;
      object-fit: cover;
    }
  }
`;

const { TextArea } = Input;

export class ViewProject extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired,
    showProjectById: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    declineProject: PropTypes.func.isRequired,
    acceptProject: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    startAgainProject: PropTypes.func.isRequired,
    getProjectCategories: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
  };
  componentDidMount() {
    // const { getProjectCategories, categories } = this.props;
  }
  componentDidMount() {
    const {
      showProjectById,
      getProjectCategories,
      match: {
        params: { id },
      },
    } = this.props;
    showProjectById(id);
    getProjectCategories();
  }
  handleChange = (value) => {
    this.setState({ value });
  };

  state = {
    showModal: false,
    selectedImage: this.props.project.images && this.props.project.images[0],
    showImageModal: false,
    showEditModal: false,
    showStartModal: false,
    totalDecline: false,
    selectedField: '',
    selectedImages: {
      banner: '',
      outer: '',
    },
    bannerImage: null,
    outerImage: null,
    images: null,
    showDonationsEditModal: false,
    value: [],
    defValue: ['project1', 'project 2'],

    // selectedMuliple: []
  };

  // imageChangehandler = (event, type) => {
  //   if (type == "banner") {
  //     this.setState(prevState => ({
  //       selectedImages: {
  //         ...prevState.selectedImages,
  //         banner: event.target.files[0]
  //       }
  //     }))
  //   }
  //   if (type == "outer") {
  //     this.setState(prevState => ({
  //       selectedImages: {
  //         ...prevState.selectedImages,
  //         outer: event.target.files[0]
  //       }
  //     }))
  //   }
  // };
  setBannerImage = (image) => this.setState({ bannerImage: image });
  setOuterImage = (image) => this.setState({ outerImage: image });
  setImages = (image) => this.setState({ images: image });

  handelCloseModal = () => {
    this.setState({ showModal: false, showImageModal: false });
    document.body.style.overflowY = 'unset';
  };
  handelCloseEditModal = () => {
    this.setState({ showEditModal: false });
    document.body.style.overflowY = 'unset';
  };
  handelCloseStartModal = () => {
    this.setState({ showStartModal: false });
    document.body.style.overflowY = 'unset';
  };
  handleSelectedImage = (selectedImage) => {
    this.setState({ selectedImage });
  };
  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };

  handelEditModal = () => {
    this.setState({ showEditModal: true });
    document.body.style.overflowY = 'hidden';
  };
  handelStartModal = () => {
    this.setState({ showStartModal: true });
    document.body.style.overflowY = 'hidden';
  };

  handelShowImagesModal = () => {
    this.setState({ showImageModal: true });
    document.body.style.overflowY = 'hidden';
  };

  handelEditDonationsModal = () => {
    this.setState({ showDonationsEditModal: true });
    // document.body.style.overflowY = 'hidden';
  };

  handelCloseEditDonationsModal = () => {
    this.setState({ showDonationsEditModal: false });
    // document.body.style.overflowY = 'hidden';
  };

  onFinish = (values) => {
    const { totalDecline } = this.state;
    const {
      declineProject,
      match: {
        params: { id },
      },
    } = this.props;

    if (totalDecline) {
      declineProject(id, '');
    } else {
      declineProject(id, values.message);
    }
    this.handelCloseModal();
  };

  onEdit = (values) => {
    const {
      updateProject,
      showProjectById,
      match: {
        params: { id },
      },
    } = this.props;
    const { bannerImage, outerImage, images } = this.state;

    const faq = [
      values.projekt,
      values.zielgruppe,
      values.unterstützen,
      values.finanzierung,
      values.hinter,
    ];

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key] || key === 'paypal_check') {
        formData.append(key, values[key]);
      }
    });

    if (bannerImage && bannerImage[0].thumbUrl) {
      formData.append('banner', bannerImage[0].originFileObj);
    }

    if (outerImage && outerImage[0].thumbUrl) {
      formData.append('outer_image', outerImage[0].originFileObj);
    }

    if (images) {
      const imgArr = images?.filter((img) => img.thumbUrl);
      imgArr.forEach((file) => {
        formData.append('images[]', file.originFileObj);
      });
    }
    formData.append('pay_out', values.pay_out);

    if (faq && faq.length > 0) {
      faq.forEach((item) => {
        if (item) {
          formData.append('faq[]', item);
        }
      });
    }

    if (values.is_ticketable) {
      formData.append('is_ticketable', true);
      formData.append('is_patreon', false);
    } else if (values.is_ticketable === null) {
      formData.append('is_ticketable', false);
      formData.append('is_patreon', false);
    } else if (values.is_ticketable === false) {
      formData.append('is_ticketable', false);
      formData.append('is_patreon', true);
    }

    updateProject(id, formData);
    this.handelCloseEditModal();
    this.handelCloseEditDonationsModal();
    setTimeout(() => {
      showProjectById(id);
    }, 3000);
  };

  onStart = (values) => {
    const {
      startAgainProject,
      showProjectById,
      match: {
        params: { id },
      },
    } = this.props;
    const { bannerImage, outerImage, images } = this.state;

    const faq = [
      values.projekt,
      values.zielgruppe,
      values.unterstützen,
      values.finanzierung,
      values.hinter,
    ];

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key] || key === 'paypal_check') {
        formData.append(key, values[key]);
      }
    });

    if (bannerImage && bannerImage[0].thumbUrl) {
      formData.append('banner', bannerImage[0].originFileObj);
    }

    if (outerImage && outerImage[0].thumbUrl) {
      formData.append('outer_image', outerImage[0].originFileObj);
    }

    if (images) {
      const imgArr = images?.filter((img) => img.thumbUrl);
      imgArr.forEach((file) => {
        formData.append('images[]', file.originFileObj);
      });
    }

    if (faq && faq.length > 0) {
      faq.forEach((item) => {
        if (item) {
          formData.append('faq[]', item);
        }
      });
    }
    formData.append('status', 'accepted');
    startAgainProject(id, formData);
    this.handelCloseStartModal();
    this.handelCloseEditDonationsModal();
    setTimeout(() => {
      showProjectById(id);
    }, 3000);
  };

  render() {
    const {
      project,
      match: {
        params: { id },
      },
      acceptProject,
    } = this.props;
    const {
      showModal,
      selectedImage,
      showImageModal,
      totalDecline,
      showEditModal,
      showStartModal,
      showDonationsEditModal,
    } = this.state;
    const { value } = this.state;
    const { categories, loading, hideProject } = this.props;

    return (
      <>
        <Layout>
          <Container>
            <TableWrapper>
              <H1>View project</H1>
              <ActionsWrapper>
                <PrimaryButton
                  minWidth={
                    project?.status === 'accepted'
                      ? '15%'
                      : project?.status === 'finished' ||
                        project?.status === 'successful'
                      ? '18%'
                      : '20%'
                  }
                  primary={false}
                  onClick={() => acceptProject(id)}
                >
                  Accept
                </PrimaryButton>
                <PrimaryButton
                  minWidth={
                    project?.status === 'accepted'
                      ? '15%'
                      : project?.status === 'finished' ||
                        project?.status === 'successful'
                      ? '18%'
                      : '20%'
                  }
                  primary={true}
                  onClick={() => this.handelShowModal()}
                >
                  Decline
                </PrimaryButton>
                <PrimaryButton
                  minWidth={
                    project?.status === 'accepted'
                      ? '15%'
                      : project?.status === 'finished' ||
                        project?.status === 'successful'
                      ? '18%'
                      : '20%'
                  }
                  onClick={() => {
                    this.setState({ totalDecline: true });
                    this.handelShowModal();
                  }}
                  className="total-decline"
                >
                  Total Decline
                </PrimaryButton>
                <PrimaryButton
                  minWidth={
                    project?.status === 'accepted'
                      ? '15%'
                      : project?.status === 'finished' ||
                        project?.status === 'successful'
                      ? '18%'
                      : '20%'
                  }
                  primary={false}
                  onClick={() => {
                    this.handelEditModal();
                  }}
                >
                  Edit
                </PrimaryButton>
                {(project?.status == 'finished' ||
                  project?.status == 'successful' ||
                  project?.status == 'accepted') && (
                  <PrimaryButton
                    minWidth={'15%'}
                    primary={false}
                    onClick={() => {
                      this.handelEditDonationsModal();
                    }}
                  >
                    Edit Donations Sum
                  </PrimaryButton>
                )}
              </ActionsWrapper>
              <ActionsWrapper>
                {(project?.status === 'finished' ||
                  project?.status === 'successful') && (
                  <PrimaryButton
                    minWidth={'18%'}
                    primary={false}
                    onClick={() => {
                      this.handelStartModal();
                    }}
                  >
                    Start Again
                  </PrimaryButton>
                )}
                <PrimaryButton
                  minWidth={'18%'}
                  onClick={() =>
                    hideProject({ id, visible: !project?.visible })
                  }
                >
                  {project?.visible ? 'Hide' : 'Unhide'}
                </PrimaryButton>
              </ActionsWrapper>
              {project && (
                <>
                  <H2>Project Status</H2>
                  <Row>
                    <Col span={6}>
                      <h3>Status:</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.status}</p>
                    </Col>
                  </Row>
                  <H2>Project Details</H2>
                  <Row>
                    <Col span={6}>
                      <h3>Title:</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.title}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>SubTile:</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.subtitle}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Description:</h3>
                    </Col>
                    <Col span={18}>
                      <TextArea
                        style={{ fontSize: '16px' }}
                        autoSize
                        readOnly
                        className="text_area_view_project"
                        value={project.description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>URL:</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.url}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>City:</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.city}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Tags</h3>
                    </Col>
                    <Col span={18}>
                      <p>
                        {project.tags && project.tags?.map((tag) => `${tag}, `)}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Project Type</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.project_type}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Account Holder</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.account_holder}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Message</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.message}</p>
                    </Col>
                  </Row>
                  <H2>Images And Video</H2>
                  <Row>
                    <Col span={6}>
                      <h3>Video</h3>
                    </Col>
                    <Col span={18}>
                      {project.video && (
                        <a
                          href={project.video}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Link
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Banner</h3>
                    </Col>
                    <Col span={18}>
                      {project.banner && (
                        <a
                          href={project.banner}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Link
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Outer Image</h3>
                    </Col>
                    <Col span={18}>
                      {project.outer_image && (
                        <a
                          href={project.outer_image}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Link
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Images</h3>
                    </Col>
                    <Col span={18}>
                      {project.images && project.images.length > 0 && (
                        <a
                          href="#!"
                          onClick={() => this.handelShowImagesModal()}
                        >
                          View Images
                        </a>
                      )}
                    </Col>
                  </Row>
                  <H2>Foundation Details</H2>
                  <Row>
                    <Col span={6}>
                      <h3>Funding days</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.funding_days} Tage</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Goal</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.goal} €</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Final Goal</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.final_goal} €</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Goal Explanation</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.goal_explanation}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Funding Start Date</h3>
                    </Col>
                    <Col span={18}>
                      <p>
                        {project.funding_end_date &&
                          moment.unix(project.funding_end_date).toString()}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Funding End Date</h3>
                    </Col>
                    <Col span={18}>
                      <p>
                        {project.funding_start_date &&
                          moment.unix(project.funding_start_date).toString()}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Donation Receipts Possible</h3>
                    </Col>
                    <Col span={18}>
                      <p>
                        {project.donation_receipts_possible &&
                          project.donation_receipts_possible.toString()}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Donations Count</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.donations_count}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Donations Sum</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.donations_sum}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Bill Possible</h3>
                    </Col>
                    <Col span={18}>
                      <p>
                        {project.bill_possible &&
                          project.bill_possible.toString()}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>PayPal status</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project?.paypal_check?.toString()}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>PayPal Email</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.paypal_email && project.paypal_email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Über commonsplace erfahren durch</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.learned_about && project.learned_about}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Ticket machen</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project?.is_ticketable?.toString()}</p>
                    </Col>
                  </Row>
                  <H2>Initiator</H2>
                  <Row>
                    <Col span={6}>
                      <h3>Name</h3>
                    </Col>
                    <Col span={18}>
                      <p>
                        {project.initiator_first_name}{' '}
                        {project.initiator_last_name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Email</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Nationality</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_nationality}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Phone</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_phone_number}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>City</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_city}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Country</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_country}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Building Number</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_building_number}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Street</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_street}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Zip Code</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.initiator_zip_code}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Birthday</h3>
                    </Col>
                    <Col span={18}>
                      <p>
                        {project.initiator_birthday &&
                          moment(project.initiator_birthday).format('LL')}
                      </p>
                    </Col>
                  </Row>
                  {/* <H2>Bank Account</H2>
                  <Row>
                    <Col span={6}>
                      <h3>IBAN</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.iban}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>BIC</h3>
                    </Col>
                    <Col span={18}>
                      <p>{project.bic}</p>
                    </Col>
                  </Row> */}
                  <H2>Social Media</H2>
                  <Row>
                    <Col span={6}>
                      <h3>FaceBook</h3>
                    </Col>
                    <Col span={18}>
                      {project.facebook_url && (
                        <a
                          href={`${project.facebook_url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Link
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>WebSite</h3>
                    </Col>
                    <Col span={18}>
                      {project.website_url && (
                        <a
                          href={`${project.website_url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Link
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <h3>Instagram</h3>
                    </Col>
                    <Col span={18}>
                      {project.instagram_url && (
                        <a
                          href={`${project.instagram_url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Link
                        </a>
                      )}
                    </Col>
                  </Row>
                  <H2>FAQ</H2>
                  <ProjectFAQs projectFaq={project.faq} />
                </>
              )}
            </TableWrapper>
          </Container>
        </Layout>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <Form onFinish={this.onFinish}>
            {totalDecline ? (
              <>
                <FormItem
                  label="Message"
                  name="message"
                  extra={`please input "${project.title}"`}
                  rules={[
                    {
                      required: true,
                    },
                    () => ({
                      validator(_, value) {
                        if (
                          value.replaceAll(' ', '') ===
                          project.title.replaceAll(' ', '')
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'Please Input correct project Title'
                        );
                      },
                    }),
                  ]}
                >
                  <Input.TextArea placeholder="Message" />
                </FormItem>
                <FormItem>
                  <PrimaryButton
                    type="primary"
                    htmltype="submit"
                    minWidth="150px"
                  >
                    Submit
                  </PrimaryButton>
                </FormItem>
              </>
            ) : (
              <>
                <FormItem
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Message" />
                </FormItem>
                <FormItem>
                  <PrimaryButton
                    type="primary"
                    htmltype="submit"
                    minWidth="150px"
                  >
                    Submit
                  </PrimaryButton>
                </FormItem>
              </>
            )}
          </Form>
        </Modal>
        <Modal
          showModal={showImageModal}
          handelCloseModal={this.handelCloseModal}
        >
          <ModalWrapper>
            <div className="selected-image">
              <img src={selectedImage} alt="selected-project-img" />
            </div>
            <div className="images">
              {project.images &&
                project.images?.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt="project-img"
                    onClick={() => this.handleSelectedImage(img)}
                  />
                ))}
            </div>
          </ModalWrapper>
        </Modal>
        <Modal
          showModal={showDonationsEditModal}
          handelCloseModal={this.handelCloseEditDonationsModal}
        >
          <H2 style={{ textAlign: 'center', marginBottom: '30px' }}>
            Edit Donations Sum
          </H2>
          <Form layout="vertical" onFinish={this.onEdit}>
            <FormItem
              name="donations_sum"
              initialValue={project?.donations_sum}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Donations Sum" size="large" />
            </FormItem>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '40px',
              }}
            >
              <PrimaryButton
                type="primary"
                htmltype="submit"
                // minWidth="150px"
              >
                Update
              </PrimaryButton>
            </div>
          </Form>
        </Modal>
        <Modal
          showModal={showEditModal}
          handelCloseModal={this.handelCloseEditModal}
        >
          <H2>Edit Project</H2>
          <Form layout="vertical" onFinish={this.onEdit}>
            <FormItem label="Title" name="title" initialValue={project.title}>
              <Input placeholder="Title" />
            </FormItem>

            <FormItem
              label="SubTile"
              name="subtitle"
              initialValue={project.subtitle}
            >
              <Input placeholder="SubTile" />
            </FormItem>

            <FormItem
              label="Description"
              name="description"
              initialValue={project.description}
            >
              <Input.TextArea placeholder="Description" />
            </FormItem>

            <FormItem
              name={'projekt'}
              label="Zweck: Was ist der genaue Zweck des Projektes?"
              initialValue={project.faq && project.faq[0]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'zielgruppe'}
              label="Ziele und Zielgruppe: Was sind die Ziele und wer ist die Zielgruppe? "
              initialValue={project.faq && project.faq[1]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'unterstützen'}
              label="Einordnung/Relevanz: Warum sollte die Community dein Projekt unterstützen?"
              initialValue={project.faq && project.faq[2]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'finanzierung'}
              label="Wofür wird das Geld benötigt?"
              initialValue={project.faq && project.faq[3]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'hinter'}
              label="Die Macher: Wer ist alles in deinem Projekt involviert?"
              initialValue={project.faq && project.faq[4]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>

            <FormItem label="URL" name="url" initialValue={project.url}>
              <Input placeholder="URL" />
            </FormItem>

            <FormItem
              label="Funding Goals"
              name="funding_days"
              initialValue={project.funding_days}
            >
              <Input placeholder="Funding Days" />
            </FormItem>

            <FormItem label="Goal" name="goal" initialValue={project.goal}>
              <Input placeholder="Goal" />
            </FormItem>

            <FormItem
              label="Final Goal"
              name="final_goal"
              initialValue={project.final_goal}
            >
              <Input placeholder="Final goal" />
            </FormItem>

            <FormItem
              name="category_ids"
              label="Kategorie deines Projekts"
              initialValue={project.category_ids}
            >
              <Select
                // defaultValue={this.def}
                showSearch
                value={value}
                tokenSeparators={[',']}
                placeholder="Wähle eine Kategorie"
                notFoundContent={
                  loading ? <Spin size="small" /> : <p>there is no result </p>
                }
                filterOption={false}
                onChange={this.handleChange}
                style={{ width: '100%' }}
                mode="multiple"
              >
                {categories &&
                  categories?.map((category) => (
                    <Option
                      key={JSON.stringify(category)}
                      value={category.value}
                    >
                      {category.category}
                    </Option>
                  ))}
              </Select>
            </FormItem>

            <FormItem
              label="PayPal Status"
              name="paypal_check"
              initialValue={project?.paypal_check}
            >
              <Radio.Group defaultValue={project?.paypal_check}>
                <div className="paypal_account">
                  <Radio className="radio_buttons large" value={true}>
                    Ja
                  </Radio>
                  <Radio className="radio_buttons large" value={false}>
                    Nein
                  </Radio>
                </div>
              </Radio.Group>
            </FormItem>
            {/* <FormItem
              name="is_ticketable"
              valuePropName="checked"
              initialValue={project.is_ticketable}
            >
              <Checkbox>Ticket machen</Checkbox>
            </FormItem> */}

            <FormItem
              name="is_ticketable"
              initialValue={
                project?.is_ticketable
                  ? project?.is_ticketable
                  : !project?.is_patreon
                  ? null
                  : false
              }
            >
              <Radio.Group>
                <div className="paypal_account">
                  <Radio className="radio_buttons large" value={null}>
                    keiner
                  </Radio>
                  <Radio className="radio_buttons large" value={true}>
                    Ticket machen
                  </Radio>
                  <Radio className="radio_buttons large" value={false}>
                    Patreon
                  </Radio>
                </div>
              </Radio.Group>
            </FormItem>
            <FormItem
              label="PayPal Email"
              name="paypal_email"
              initialValue={project.paypal_email}
            >
              <Input placeholder="PayPal Email" />
            </FormItem>
            <FormItem
              label="Goal Explanation"
              name="goal_explanation"
              initialValue={project.goal_explanation}
            >
              <Input placeholder="Goal Explanation" />
            </FormItem>

            <FormItem label="City" name="city" initialValue={project.city}>
              <Input placeholder="City" />
            </FormItem>

            {/* <FormItem
              label="Message"
              name="message"
            >
              <Input placeholder="Message" defaultValue={project.message} />
            </FormItem> */}

            <FormItem
              label="Facebook URL"
              name="facebook_url"
              initialValue={project.facebook_url}
            >
              <Input placeholder="Facebook Url" />
            </FormItem>

            <FormItem
              label="Instagram URL"
              name="instagram_url"
              initialValue={project.instagram_url}
            >
              <Input placeholder="Instagram Url" />
            </FormItem>
            <FormItem
              label="Website URL"
              name="website_url"
              initialValue={project.website_url}
            >
              <Input placeholder="Website Url" />
            </FormItem>
            <FormItem
              label="Payout"
              name="pay_out"
              initialValue={project?.pay_out}
            >
              <Radio.Group>
                <div className="paypal_account">
                  <Radio className="radio_buttons large" value={true}>
                    Ja
                  </Radio>
                  <Radio className="radio_buttons large" value={false}>
                    Nein
                  </Radio>
                </div>
              </Radio.Group>
            </FormItem>
            <FormItem name="banner">
              <UploadImage
                required={false}
                multiple={false}
                label={'Banner'}
                // name={'banner'}
                setImage={this.setBannerImage}
                fileList={[
                  `${
                    project.banner &&
                    project.banner &&
                    project.banner.length > 0
                      ? project.banner
                      : null
                  }`,
                ]}
              />
            </FormItem>

            <FormItem name="outer_image">
              <UploadImage
                required={false}
                multiple={false}
                label={'Outer Image'}
                // name={'outer_image'}
                setImage={this.setOuterImage}
                fileList={[
                  `${
                    project.outer_image &&
                    project.outer_image &&
                    project.outer_image.length > 0
                      ? project.outer_image
                      : null
                  }`,
                ]}
              />
            </FormItem>

            <FormItem name="images[]">
              <UploadImage
                required={false}
                multiple={true}
                label={'images'}
                // name={'images[]'}
                setImage={this.setImages}
                fileList={
                  project.images && project.images && project.images.length > 0
                    ? project.images
                    : null
                }
              />
            </FormItem>

            {/* <FormItem
              label="tags"
              name="tags"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="tags" />
            </FormItem> */}

            <FormItem>
              <PrimaryButton type="primary" htmltype="submit" minWidth="150px">
                Update
              </PrimaryButton>
            </FormItem>
          </Form>
        </Modal>
        <Modal
          showModal={showStartModal}
          handelCloseModal={this.handelCloseStartModal}
        >
          <H2>Start Again</H2>
          <Form layout="vertical" onFinish={this.onStart}>
            <FormItem label="Title" name="title" initialValue={project.title}>
              <Input placeholder="Title" />
            </FormItem>

            <FormItem
              label="SubTile"
              name="subtitle"
              initialValue={project.subtitle}
            >
              <Input placeholder="SubTile" />
            </FormItem>

            <FormItem
              label="Description"
              name="description"
              initialValue={project.description}
            >
              <Input placeholder="Description" />
            </FormItem>

            <FormItem
              name={'projekt'}
              label="Zweck: Was ist der genaue Zweck des Projektes?"
              initialValue={project.faq && project.faq[0]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'zielgruppe'}
              label="Ziele und Zielgruppe: Was sind die Ziele und wer ist die Zielgruppe? "
              initialValue={project.faq && project.faq[1]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'unterstützen'}
              label="Einordnung/Relevanz: Warum sollte die Community dein Projekt unterstützen?"
              initialValue={project.faq && project.faq[2]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'finanzierung'}
              label="Wofür wird das Geld benötigt?"
              initialValue={project.faq && project.faq[3]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              name={'hinter'}
              label="Die Macher: Wer ist alles in deinem Projekt involviert?"
              initialValue={project.faq && project.faq[4]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea />
            </FormItem>

            <FormItem label="URL" name="url" initialValue={project.url}>
              <Input placeholder="URL" />
            </FormItem>

            <FormItem
              label="Funding Goals"
              name="funding_days"
              initialValue={project.funding_days}
            >
              <Input placeholder="Funding Days" />
            </FormItem>

            <FormItem label="Goal" name="goal" initialValue={project.goal}>
              <Input placeholder="Goal" />
            </FormItem>

            <FormItem
              label="Final Goal"
              name="final_goal"
              initialValue={project.final_goal}
            >
              <Input placeholder="Final goal" />
            </FormItem>
            <FormItem
              label="PayPal Status"
              name="paypal_check"
              initialValue={project.paypal_check}
            >
              <Radio.Group>
                <div className="paypal_account">
                  <Radio className="radio_buttons large" value={true}>
                    Ja
                  </Radio>
                  <Radio className="radio_buttons large" value={false}>
                    Nein
                  </Radio>
                </div>
              </Radio.Group>
            </FormItem>
            <FormItem
              label="PayPal Email"
              name="paypal_email"
              initialValue={project.paypal_email}
            >
              <Input placeholder="PayPal Email" />
            </FormItem>
            <FormItem
              label="Goal Explanation"
              name="goal_explanation"
              initialValue={project.goal_explanation}
            >
              <Input placeholder="Goal Explanation" />
            </FormItem>

            <FormItem label="City" name="city" initialValue={project.city}>
              <Input placeholder="City" />
            </FormItem>

            {/* <FormItem
              label="Message"
              name="message"
            >
              <Input placeholder="Message" defaultValue={project.message} />
            </FormItem> */}

            <FormItem
              label="Facebook URL"
              name="facebook_url"
              initialValue={project.facebook_url}
            >
              <Input placeholder="Facebook Url" />
            </FormItem>

            <FormItem
              label="Instagram URL"
              name="instagram_url"
              initialValue={project.instagram_url}
            >
              <Input placeholder="Instagram Url" />
            </FormItem>
            <FormItem
              label="Website URL"
              name="website_url"
              initialValue={project.website_url}
            >
              <Input placeholder="Website Url" />
            </FormItem>
            <FormItem name="banner">
              <UploadImage
                required={false}
                multiple={false}
                label={'Banner'}
                // name={'banner'}
                setImage={this.setBannerImage}
                fileList={[
                  `${
                    project.banner &&
                    project.banner &&
                    project.banner.length > 0
                      ? project.banner
                      : null
                  }`,
                ]}
              />
            </FormItem>

            <FormItem name="outer_image">
              <UploadImage
                required={false}
                multiple={false}
                label={'Outer Image'}
                // name={'outer_image'}
                setImage={this.setOuterImage}
                fileList={[
                  `${
                    project.outer_image &&
                    project.outer_image &&
                    project.outer_image.length > 0
                      ? project.outer_image
                      : null
                  }`,
                ]}
              />
            </FormItem>

            <FormItem name="images[]">
              <UploadImage
                required={false}
                multiple={true}
                label={'images'}
                // name={'images[]'}
                setImage={this.setImages}
                fileList={
                  project.images && project.images && project.images.length > 0
                    ? project.images
                    : null
                }
              />
            </FormItem>

            {/* <FormItem
              label="tags"
              name="tags"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="tags" />
            </FormItem> */}

            <FormItem>
              <PrimaryButton type="primary" htmltype="submit" minWidth="150px">
                Start
              </PrimaryButton>
            </FormItem>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { project } = state.admin;
  const { categories, loading, errors } = state.projects;

  return {
    project,
    categories,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showProjectById: (id) => dispatch(AdminActions.showProjectById(id)),
  declineProject: (id, message) =>
    dispatch(AdminActions.declineProject(id, message)),
  acceptProject: (id) => dispatch(AdminActions.acceptProject(id)),
  updateProject: (id, values) =>
    dispatch(AdminActions.updateProjectById(id, values)),
  startAgainProject: (id, values) =>
    dispatch(AdminActions.startProjectById(id, values)),
  getProjectCategories: () => dispatch(ProjectsActions.getProjectCategories()),
  hideProject: (id) => dispatch(AdminActions.hideProject(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewProject);
